.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}
